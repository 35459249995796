const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  quest: yup.mixed(),
  profile: yup.mixed(),
  completedAt: yup.date().nullable().required(),
  pointsEarned: yup.number().min(0).default(0),
  status: yup.string().oneOf(['approved', 'denied']).default('approved'),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const questCompletionSchemaPartial = yup.object().shape(shape)
const questCompletionSchema = questCompletionSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { questCompletionSchema, questCompletionSchemaPartial }
