import App, { state } from 'cerebral'
import Devtools from 'cerebral/devtools'
import account from './account'
import accountSettings from './account-settings'
import albums from './albums'
import alerts from './alerts'
import appreciationAwards from './appreciation-awards'
import appointments from './appointments'
import capacityLocations from './capacity-locations'
import carpools from './carpools'
import calendars from './calendars'
import contactInfos from './contact-infos'
import customReports from './custom-reports'
import events from './events'
import eventAttendances from './event-attendances'
import eventInstances from './event-instances'
import feedback from './feedback'
import invitations from './invitations'
import info from './info'
import kiosks from './kiosks'
import kioskDevices from './kiosk-devices'
import lifts from './lifts'
import libraryDailyContents from './library-daily-contents'
import libraryItems from './library-items'
import libraryProviders from './library-providers'
import locationDevices from './location-devices'
import locationDeviceEvents from './location-device-events'
import locationRoutes from './location-routes'
import locationRouteRequests from './location-route-requests'
import locationRouteDispatchRequests from './location-route-dispatch-requests'
import locationVehicleMaintenanceLogs from './location-vehicle-maintenance-logs'
import locationVehicleMaintenanceSchedules from './location-vehicle-maintenance-schedules'
import locationVehicles from './location-vehicles'
import locationVehicleShifts from './location-vehicle-shifts'
import mealLocations from './meal-locations'
import medias from './medias'
import mobileWelcomes from './mobile-welcomes'
import mapPoints from './map-points'
import mapAreas from './map-areas'
import mapCategories from './map-categories'
import publications from './publications'
import notifications from './notifications'
import prices from './prices'
import pricings from './pricings'
import profiles from './profiles'
import profileAccessCodes from './profile-access-codes'
import profileRelationships from './profile-relationships'
import reservations from './reservations'
import reservationSchedules from './reservation-schedules'
import reservationSlots from './reservation-slots'
import resourceGroups from './resource-groups'
import resources from './resources'
import rewards from './rewards'
import rewardRedemptions from './reward-redemptions'
import quests from './quests'
import questCompletions from './quest-completions'
import rideProducts from './ride-products'
import rideProductSubscriptions from './ride-product-subscriptions'
import seasons from './seasons'
import eventRsvps from './event-rsvps'
import route from './route'
import signDevices from './sign-devices'
import snowStats from './snow-stats'
import snowReports from './snow-reports'
import statusUpdates from './status-updates'
import surveys from './surveys'
import surveyResponses from './surveyResponses'
import trails from './trails'
import ui from './ui'
import updates from './updates'
import uploads from './uploads'
import { storage } from './providers'
import { subscribeOrganization } from '../lib/util/api-client'
import intercom from '../lib/util/intercom'

// Two steps will enable Cerebral debugger.
// 1. Set VITE_CEREBRAL_DEBUG env var
// 2. Set CEREBRAL_DEBUG_HOST to debugger URL in localStorage
let devtools
if (import.meta.env.VITE_CEREBRAL_DEBUG) {
  const host = localStorage.getItem('CEREBRAL_DEBUG_HOST')

  if (host) {
    devtools = Devtools({ host, https: true, bigComponentsWarning: 15 })
  }
}

const sync = {
  'account.redirectedFromPath': 'account.redirectedFromPath',
  'account.orgSlug': 'account.orgSlug',
  'account.locationSlug': 'account.locationSlug',
  'events.calendarPrint': 'events.calendarPrint',
  'locationVehicles.shownOnMap': 'locationVehicles.shownOnMap',
  'calendars.currentCalendar': 'calendars.currentCalendar',
  'mealLocations.currentLocation': 'mealLocations.currentLocation',
  'profiles.filter.levelOfCare': 'profiles.filter.levelOfCare',
  'account.invitationParams': 'account.invitationParams',
}

const app = App(
  {
    state: {
      errors: {},
    },
    modules: {
      account,
      accountSettings,
      albums,
      alerts,
      appreciationAwards,
      appointments,
      calendars,
      carpools,
      capacityLocations,
      contactInfos,
      customReports,
      events,
      eventAttendances,
      eventInstances,
      feedback,
      invitations,
      info,
      kioskDevices,
      kiosks,
      lifts,
      libraryDailyContents,
      libraryItems,
      libraryProviders,
      locationDevices,
      locationDeviceEvents,
      locationRoutes,
      locationRouteRequests,
      locationRouteDispatchRequests,
      locationVehicleMaintenanceLogs,
      locationVehicleMaintenanceSchedules,
      locationVehicles,
      locationVehicleShifts,
      medias,
      mealLocations,
      mobileWelcomes,
      mapPoints,
      mapAreas,
      mapCategories,
      notifications,
      publications,
      prices,
      pricings,
      profiles,
      profileAccessCodes,
      profileRelationships,
      reservations,
      reservationSchedules,
      reservationSlots,
      resourceGroups,
      resources,
      rewards,
      rewardRedemptions,
      quests,
      questCompletions,
      rideProducts,
      rideProductSubscriptions,
      eventRsvps,
      route,
      signDevices,
      seasons,
      snowStats,
      snowReports,
      statusUpdates,
      surveys,
      surveyResponses,
      trails,
      ui,
      updates,
      uploads,
      storage: storage({ sync }),
      api: subscribeOrganization, // Keep after storage, depends on hydration.
    },
    providers: {
      intercom: { setUser: intercom },
    },
    catch: [
      [
        Error,
        ({ props: { error, errorPath = 'app' }, store }) => {
          console.error('FALLBACK', error)
          store.set(state`errors.${errorPath}`, error)
        },
      ],
    ],
  },
  {
    devtools,
    returnSequencePromise: true,
  }
)

export default app
